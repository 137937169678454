.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; /* Align items at the top */
  max-width: 1200px;
  margin: 0 auto;
  padding: 0px;
}


.left-column, .right-column {
  width: 200px; /* Adjust the width as needed */
  /* Additional styles for your ad containers */
}

.bottom-fixed {
  width:100vw;
  max-width: 700px;
  position:fixed;
  bottom:0px;
}

.center-column {
  flex-grow: 1; /* Allow the center column to grow and take available space */
  margin: 0px 20px 20px 0px; /* Add spacing between columns */
}

.category {
  margin-left: 0px; /* Adjust the margin as needed */
}

.party {
  margin-left: 20px; /* Adjust the margin as needed */
}

.item {
  margin-left: 0px; /* Adjust the margin as needed */
}
